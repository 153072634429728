import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import { logoutUrl } from '../config/config';


export class Header extends Component {
  
  constructor(props) {
    super(props)
  
    this.state = {
        user:"",
        logoff:false
    }
    this.logout = this.logout.bind(this)
  }

  componentDidMount(){
    var userName = sessionStorage.getItem("displayName");
    if(userName!== undefined && userName!== ""){
        this.setState({user:userName,logoff:false})
    }
  }

  logout(e){
    e.preventDefault();
    var id_token = sessionStorage.getItem("id_token")
    var endsessionUrl = logoutUrl + id_token
    console.log(endsessionUrl)
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${endsessionUrl}`)
  }

  

  render() {
    const {user,logoff} = this.state
    
    return (
        
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        
        <a className="navbar-brand ps-3" href="/">E-VO Leads</a>
        
        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
        
        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
            <div className="input-group">
                <input className="form-control" type="text" placeholder="Recherche..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
            </div>
        </form>
        
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#!">{user}</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" onClick={this.logout}>Logout <i className="fas fa-power-off fa-fw"></i></a></li>
                </ul>
            </li>
        </ul>
    </nav>
    )
  }
}

export default Header
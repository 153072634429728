import React, { Component } from 'react'
import { ActiveRouteConsumer } from '../context/ActiveRouteContext'
import Dash from '../Pages/Dash'
import Logs from '../Pages/Logs'
import Mails from '../Pages/Mails'

export class Main extends Component {

  render() {
    return (
        <>
        <ActiveRouteConsumer>
            {
            (val) => {
                if(val === 'dash'){
                    return (
                        <Dash />
                    )
                }
                if(val === 'logs'){
                    return (
                        <Logs />
                    )
                }
                if(val === 'mails'){
                    return (
                        <Mails />
                    )
                }
            } 
            }
        </ActiveRouteConsumer>
        </>
    )
  }
}

export default Main
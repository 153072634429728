import React, { Component } from 'react'
import { authUrl } from '../config/config'

export class Login extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
      }
    }
    
    doClick(){
        console.log(authUrl)
        window.location.replace(`${authUrl}`)
        
    }


  render() {
        
        return (
        
        <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                        <div className="card-body">
                                        <a className="btn btn-primary" onClick={this.doClick}>Connexion ARCA</a>
                                        </div>
                                        <div className="card-footer text-center py-3">
                                            <div className="small"><a >Need an account? Sign up!</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
                    <footer className="py-4 bg-light mt-auto">
                        <div className="container-fluid px-4">
                            <div className="d-flex align-items-center justify-content-between small">
                                <div className="text-muted">Copyright &copy; Your Website 2023</div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
  }
}

export default Login
import React from "react"

const _isLocalhost = window.location.port.indexOf('3000') > -1;
const _isOpe = window.location.origin.indexOf('https://ope.evl.ope.aws.renault.com') > -1;
const apiUrl = _isLocalhost ? 'http://localhost:8080/api' : window.location.origin + '/api';

const cliendId = _isOpe ? 'irn-71403_ope_code_p1r1y2kkvbud' : 'irn-71403_uat_code_oygq73xolrvy';
const evlHosts = ['http://localhost:3000','https://int.evl.dev.aws.renault.com','https://re7.evl.re7.aws.renault.com','https://ope.evl.ope.aws.renault.com']
const authEndpoint = _isOpe? 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize':'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize'
const logoutEndpoint = _isOpe? 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/logout':'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/logout'
const redirectUri  = evlHosts.includes(window.location.origin) ? window.location.origin+'/auth_callback' :''
const redirectPostLogout  = evlHosts.includes(window.location.origin) ? window.location.origin+'/logout' :''
const authUrl = authEndpoint +'?state=3gTy3bZJzgba0MYQcYTbQKsuKIfyCA7lHmRdbeFk&client_id='+cliendId+'&grant_type=authorization_code&response_type=code&scope=openid offline_access alliance_profile&redirect_uri='+redirectUri
const logoutUrl = logoutEndpoint +'?post_logout_redirect_uri='+redirectPostLogout+'&id_token_hint='

export { apiUrl,authUrl,logoutUrl}
import React, { Component } from 'react';
import DataTable, { ExpanderComponentProps }  from 'react-data-table-component';
import { s3BucketColumnsMails, mailSubscriber, mailDeleteObjectService } from '../models/dataTableColumns';
import { saveAs } from 'file-saver';
import axiosHttp from '../utils/AxiosHttp'


export class Mails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            selectedFile: null,
            DataisLoaded: false,
            objectToDelete: ''
        };
    }
    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
      };
       
      // On file upload (click the upload button)
      onFileUpload = () => {
        // Create an object of formData
        const formData = new FormData();
       
        // Update the formData object
        formData.append(
          "file",
          this.state.selectedFile,
          this.state.selectedFile.name
        );
       
        // Details of the uploaded file
        console.log(this.state.selectedFile);
       
        // Request made to the backend api
        // Send formData object
         axiosHttp.post(`/uploadtomail`, formData).then((res)=>{
          console.log(res)
            if(res['data'] !== undefined && res['data']['res_upload'] !== undefined && res['data']['res_upload'] === 'ok'){
              this.setState({ selectedFile: null });
              document.getElementById('mailUpload').value = "";
              document.getElementById('closeUploadModalLabel').click();
              this.loadMails();
            }
        });
      };
       
      doDelete = () => {
        var obj = sessionStorage.getItem('mailsObjectToDelete');
        const objSplit = obj.split('/');
        if(objSplit != undefined && objSplit.length === 2){
            const objName = objSplit[1]
            const objType = objSplit[0]
            var formdata = new FormData();
            formdata.append('name', `${objName}`)
            formdata.append('eltType', `${objType}`)
            console.log('item to delete',sessionStorage.getItem('mailsObjectToDelete'))
             axiosHttp.post(`/popObj`,formdata).then((res)=>{
                console.log(res)
                if(res['data'] !== undefined && res['data']['res_delete'] !== undefined && res['data']['res_delete'] === 'ok'){
                    this.loadMails();
                    document.getElementById('closemodalmailconfirm').click();
                    sessionStorage.setItem('mailsObjectToDelete','');
                }
            });
        }
        
  }

  componentDidMount() {
    this.loadMails();
    mailSubscriber.subscribe((val) => { this.setState({objectToDelete: val})})
  }

  loadMails = () => {
      axiosHttp.get(`/listallmails`)
              .then(res => {
                this.setState({
                    items: res.data,
                    DataisLoaded: true
                });
            })
  }

  downloadMails = async () => {
      const res = await axiosHttp.get(`/fetchMails`);
      const blob = new Blob([res.data], {
        type: 'application/octet-stream'
      })
      saveAs(blob, 'mails.zip')
  }

  render() {
    // data provides access to your row data
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    const { DataisLoaded, items, objectToDelete } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Loading ... </h1> </div> ;
   
    return (
        
        <main>
                <div className="container-fluid px-4">
                    <h1 className="mt-4"></h1>
                    <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active"><span><button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#uploadModal">Upload mail <span><i className="fas fa-upload"></i></span></button></span>
                    </li>
                    </ol>
                    <div className="card mb-4">
                        <div className="card-body">
                            <DataTable title="Liste de mails" columns={s3BucketColumnsMails} data={items} expandableRows expandableRowsComponent={ExpandedComponent} pagination highlightOnHover />
                        </div>
                    </div>

                </div>
                <div className="modal fade" id="uploadModal" tabIndex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="uploadModalLabel">Upload de mail retouché</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <div>
                                
                                <div>
                <input type="file" id="mailUpload" onChange={this.onFileChange} />
                
            </div>
          
        </div>
      </div>
      <div className="modal-footer">
      <button type="button" className="btn btn-secondary" id="closeUploadModalLabel" data-bs-dismiss="modal">Annuler</button>
        <button type="button" onClick={this.onFileUpload} className="btn btn-primary">upload</button>
      </div>
    </div>
  </div>
</div>


<div className="modal fade" id="modalmailsconfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalmailsconfirmLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div style={{padding:50+'px'}}>
       <span><span style={{color:'orange',fontSize:45+'px'}}><i className="fa fa-circle-exclamation"></i></span><span><h5>Confirmez-vous supprimer cet objet ?</h5></span></span> 
        <span>{objectToDelete}</span>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" id="closemodalmailconfirm" data-bs-dismiss="modal">Annuler</button>
        <button type="button" className="btn btn-danger" onClick={this.doDelete}>Supprimer</button>
      </div>
    </div>
  </div>
</div>
</main>
    )
  }
}

export default Mails
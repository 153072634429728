import logo from './logo.svg';
import './App.css';
import MainContent from './components/MainContent'
import {ActiveRouteProvider} from './context/ActiveRouteContext'
import {BrowserRouter as Router,Routes,Route } from "react-router-dom"
import React from 'react';
import Login from './Auth/Login';
import AuthCallback from './Auth/AuthCallback';
import PrivateRoute from './utils/PrivateRoute';
import Logout from './Auth/Logout';

function App() {
  return (
    <div className="App">
      
      <Router>
          <Routes>
              <Route element={<PrivateRoute/>}>
              <Route exact path="/" element={<ActiveRouteProvider value="dash"><MainContent/></ActiveRouteProvider> } />
              <Route exact path="/logs" element={<ActiveRouteProvider value="logs"><MainContent/></ActiveRouteProvider> } />
              <Route exact path="/mails" element={<ActiveRouteProvider value="mails"><MainContent/></ActiveRouteProvider> } />
              </Route>
              <Route path="/logout" element={ <Logout />} />
              <Route path="/login" element={ <Login />} />
              <Route exact path='/auth_callback' element={ <AuthCallback /> } />
          </Routes>
        </Router>
    
    </div>
  );
}

export default App;

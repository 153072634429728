import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { leadsColumns } from '../models/dataTableColumns';
import axiosHttp from '../utils/AxiosHttp'



export default class Dash extends Component {
  constructor(props) {
        super(props)
        console.log('main',props)
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }

    componentDidMount() {

        axiosHttp.get('/leads-fetch')
                        .then(res => {
                            this.setState({
                                items: res.data.rows,
                                DataisLoaded: true
                            });
                        })
        }

  render() {
    
    const { DataisLoaded, items } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Loading ... </h1> </div> ;
    // data provides access to your row data
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    return (
        
        <main>
                <div className="container-fluid px-4">
                    <h1 className="mt-4"></h1>
                    <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                        <li className="breadcrumb-item active">Leads</li>
                    </ol>
                    <div className="card mb-4">
                        <div className="card-body">
                            <DataTable title="Generated Leads" columns={leadsColumns} data={items} expandableRows expandableRowsComponent={ExpandedComponent} pagination highlightOnHover />
                        </div>
                    </div>

                </div>
            </main>
    )
  }
}

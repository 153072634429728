import React from 'react';
import {BehaviorSubject} from 'rxjs';
import axiosHttp from '../utils/AxiosHttp'
import { saveAs } from 'file-saver';

const mailSubscriber = new BehaviorSubject('')
const logsSubscriber = new BehaviorSubject('')
const mailDeleteObjectService = {
    send: function(val){
        mailSubscriber.next(val);
    }
}

const logsDeleteObjectService = {
    send: function(val){
        logsSubscriber.next(val);
    }
}


const handleEdit = (data,objectType) => {
    console.log(data);
    if(objectType=='logs'){
        const logsObjectToDelete = data.Key;
        logsSubscriber.next(logsObjectToDelete);
        sessionStorage.setItem('logsObjectToDelete',data.Key)
    }
    if(objectType=='mails'){
        const mailsObjectToDelete = data.Key;
        mailSubscriber.next( mailsObjectToDelete);
        sessionStorage.setItem('mailsObjectToDelete',data.Key);
    }
  };

const downloadFile = (obj) => {
    const objSplit = obj.split('/');
        if(objSplit != undefined && objSplit.length === 2){
            const objName = objSplit[1]
            const objType = objSplit[0]
            var formdata = new FormData();
            formdata.append('name', `${objName}`)
            formdata.append('eltType', `${objType}`)           
             axiosHttp.post(`/fetchObj`,formdata).then((res)=>{
                console.log(res)
                if(res['data'] !== undefined){
                    const blob = new Blob([res.data], {
                        type: 'application/octet-stream'
                      })
                      saveAs(blob, objName)
                }
            });
        }
}

const addDropDown = (param) => {
    return (
        <>
        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modallogsconfirm" onClick={() => handleEdit(param,'logs')}>
            <span><i className="fa fa-trash-can"></i></span>
        </button>
        </>
    );
};

const addDropDownMails = (param) => {
    return (
        <>
        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalmailsconfirm" onClick={() => handleEdit(param,'mails')}>
            <span><i className="fa fa-trash-can"></i></span>
        </button>
        </>
    );
};

const downloadLogs = (param) => {
    return (
        <>
        <button type="button" className="btn btn-outline-primary"  onClick={() => downloadFile(param.Key)}>
            <span><i className="fa fa-download"></i></span>
        </button>
        </>
    );
};

const downloadnMails = (param) => {
    return (
        <>
        <button type="button" className="btn btn-outline-primary"  onClick={() => downloadFile(param.Key)}>
            <span><i className="fa fa-download"></i></span>
        </button>
        </>
    );
};
  

const s3BucketColumns = [
        {
            name: 'Key',
            selector: row => row.Key,
            sortable: true,
        },
        {
            name: 'LastModified',
            selector: row => row.LastModified,
            sortable: true,
        },
        {
            name: 'Size',
            selector: row => row.Size,
            sortable: true,
        },
        {
            name: "supprimer",
            cell: (param) => addDropDown(param)
        },
        {
            name: "Telecharger",
            cell: (param) => downloadLogs(param)
        }
    ];

const s3BucketColumnsMails = [
    {
        name: 'Key',
        selector: row => row.Key,
        sortable: true,
    },
    {
        name: 'LastModified',
        selector: row => row.LastModified,
        sortable: true,
    },
    {
        name: 'Size',
        selector: row => row.Size,
        sortable: true,
    },
    {
        name: "supprimer",
        cell: (param) => addDropDownMails(param)
    },
    {
        name: "Telecharger",
        cell: (param) => downloadnMails(param)
    }
];

const leadsColumns = [
    {
        name: 'dealerofinterest',
        selector: row => row.dealerofinterest,
        sortable: true,
    },
    {
        name: 'tradeinquoteurl',
        selector: row => row.tradeinquoteurl,
        sortable: true,
    },
    {
        name: 'brandofinterest',
        selector: row => row.brandofinterest,
        sortable: true,
    },
    {
        name: 'modelofinterest',
        selector: row => row.modelofinterest,
        sortable: true,
    },
    {
        name: 'version',
        selector: row => row.version,
        sortable: true,
    },
    {
        name: 'stockid',
        selector: row => row.stockid,
        sortable: true,
    },
    {
        name: 'registrationnumber',
        selector: row => row.registrationnumber,
        sortable: true,
    },
    {
        name: 'firstregistrationdate',
        selector: row => row.firstregistrationdate,
        sortable: true,
    },
    {
        name: 'vin',
        selector: row => row.vin,
        sortable: true,
    },
    {
        name: 'discountprice',
        selector: row => row.discountprice,
        sortable: true,
    },
    {
        name: 'fundingrequest',
        selector: row => row.fundingrequest,
        sortable: true,
    },
    {
        name: 'takeback',
        selector: row => row.takeback,
        sortable: true,
    },
    {
        name: 'mileage',
        selector: row => row.mileage,
        sortable: true,
    },
    {
        name: 'energy',
        selector: row => row.energy,
        sortable: true,
    },
    {
        name: 'title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'firstname',
        selector: row => row.firstname,
        sortable: true,
    },
    {
        name: 'lastname',
        selector: row => row.lastname,
        sortable: true,
    },
    {
        name: 'email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'fixedphone',
        selector: row => row.fixedphone,
        sortable: true,
    },
    {
        name: 'mobilephone',
        selector: row => row.mobilephone,
        sortable: true,
    },
    {
        name: 'clientrequestdescription',
        selector: row => row.clientrequestdescription,
        sortable: true,
    },
    {
        name: 'clienttype',
        selector: row => row.clienttype,
        sortable: true,
    },
    {
        name: 'companyname',
        selector: row => row.companyname,
        sortable: true,
    },
    {
        name: 'companyregistrationid',
        selector: row => row.companyregistrationid,
        sortable: true,
    },
    {
        name: 'addressline',
        selector: row => row.addressline,
        sortable: true,
    },
    {
        name: 'postalcode',
        selector: row => row.postalcode,
        sortable: true,
    },
    {
        name: 'city',
        selector: row => row.city,
        sortable: true,
    },
    {
        name: 'campaign',
        selector: row => row.campaign,
        sortable: true,
    },
    {
        name: 'leadcountry',
        selector: row => row.leadcountry,
        sortable: true,
    },
    {
        name: 'leadprovider',
        selector: row => row.leadprovider,
        sortable: true,
    },
    {
        name: 'leadformname',
        selector: row => row.leadformname,
        sortable: true,
    },
    {
        name: 'leadsource',
        selector: row => row.leadsource,
        sortable: true,
    },
    {
        name: 'platformbrand',
        selector: row => row.platformbrand,
        sortable: true,
    },
    {
        name: 'typeofinterest',
        selector: row => row.typeofinterest,
        sortable: true,
    },
    {
        name: 'subtypeofinterest',
        selector: row => row.subtypeofinterest,
        sortable: true,
    },
    {
        name: 'origin',
        selector: row => row.origin,
        sortable: true,
    },
    {
        name: 'context',
        selector: row => row.context,
        sortable: true,
    },
    {
        name: 'device',
        selector: row => row.device,
        sortable: true,
    },
    {
        name: 'leadsubmissionid',
        selector: row => row.leadsubmissionid,
        sortable: true,
    },
    {
        name: 'leadsubmissiontimestamp',
        selector: row => row.leadsubmissiontimestamp,
        sortable: true,
    },
    {
        name: 'parsingdbtimestamp',
        selector: row => row.parsingdbtimestamp,
        sortable: true,
    },
    {
        name: 'parsedmail',
        selector: row => row.parsedmail,
        sortable: true,
    },
  ];

  export {leadsColumns, s3BucketColumns, s3BucketColumnsMails, mailSubscriber,mailDeleteObjectService,logsSubscriber,logsDeleteObjectService}

  
  
import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { s3BucketColumns,logsObjectToDeleteSubscriber, logsSubscriber } from '../models/dataTableColumns';
import axiosHttp from '../utils/AxiosHttp'
import { saveAs } from 'file-saver';

export class Logs extends Component {
    constructor(props) {
        super(props)
        console.log('main',props)
        this.state = {
            items: [],
            DataisLoaded: false,
            objectToDelete: ''
        };
    }

    componentDidMount() {
      this.loadLogs();
      logsSubscriber.subscribe((val) => { this.setState({objectToDelete: val})})  
    }

    loadLogs = ()=>{
        axiosHttp.get(`/listalllogs`)
                        .then(res => {
                            this.setState({
                                items: res.data,
                                DataisLoaded: true
                            });
                        })
    }

    downloadLogs = async () => {
        const res = await axiosHttp.get(`/fetchLogs`);
        const blob = new Blob([res.data], {
            type: 'application/octet-stream'
          })
          saveAs(blob, 'logs.zip')
    }
    

    doDelete = () => {
        var obj = sessionStorage.getItem('logsObjectToDelete');
        const objSplit = obj.split('/');
        if(objSplit != undefined && objSplit.length === 2){
            const objName = objSplit[1]
            const objType = objSplit[0]
            var formdata = new FormData();
            formdata.append('name', `${objName}`)
            formdata.append('eltType', `${objType}`)
            console.log('item to delete',sessionStorage.getItem('logsObjectToDelete'))
             axiosHttp.post(`/popObj`,formdata).then((res)=>{
                console.log(res)
                if(res['data'] !== undefined && res['data']['res_delete'] !== undefined && res['data']['res_delete'] === 'ok'){
                    this.loadLogs();
                    document.getElementById('closemodallogsconfirm').click();
                }
            });
        }
        
  }

  render() {
    
    const { DataisLoaded, items, objectToDelete } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Loading ... </h1> </div> ;
    // data provides access to your row data
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
   
    return (
            <main>
                <div className="container-fluid px-4">
                    <h1 className="mt-4"></h1>
                    {/* <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item active"><span><button onClick={this.downloadLogs} className="btn btn-outline-primary"> Télécharger <span><i className="fas fa-download"></i></span></button></span></li>
                    </ol> */}
                    
                    <div className="card mb-4">
                        <div className="card-body">
                            <DataTable title="Liste de logs" columns={s3BucketColumns} data={items} expandableRows expandableRowsComponent={ExpandedComponent} pagination highlightOnHover />
                        </div>
                    </div>

                </div>

                <div className="modal fade" id="modallogsconfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalconfirmLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div style={{padding:50+'px'}}>
                    <span><span style={{color:'orange',fontSize:45+'px'}}><i className="fa fa-circle-exclamation"></i></span><span><h5>Confirmez-vous supprimer cet objet ?</h5></span></span> 
                        <span>{objectToDelete}</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" id="closemodallogsconfirm" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-danger" onClick={this.doDelete}>Supprimer</button>
                    </div>
                    </div>
                </div>
                </div>
            </main>
    )
  }
}

export default Logs
import React, { Component } from 'react'
import {Link } from "react-router-dom"

export class Sidebar extends Component {
  render() {
    return (
        <div id="layoutSidenav_nav">
        
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <Link to="/" className="nav-link">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Dashboard
                    </Link>
                    
                    <Link to="/logs" className="nav-link">
                        <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                        Logs
                    </Link>

                    <Link to="/mails" className="nav-link">
                        <div className="sb-nav-link-icon"><i className="fas fa-table"></i></div>
                        Mails
                    </Link>
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                {sessionStorage.getItem('displayName')}
            </div>
        </nav>
       
  
    </div>
    )
  }
}

export default Sidebar
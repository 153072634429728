import React, { Component } from 'react'
import Footer from './Footer'
import Main from './Main'
import Header from './Header';
import Sidebar from './Sidebar'
export class MainContent extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
    }
    console.log(props)
  }
  
  render() {
    return (
      <>
      <Header />
      <div id="layoutSidenav">
        <Sidebar />
      <div id="layoutSidenav_content">
        <Main/>
        <Footer/>
      </div>
      </div>
      </>
    )
  }
}

export default MainContent
import React, { Component } from 'react'
import axiosHttp from '../utils/AxiosHttp'
import { Navigate } from "react-router-dom";

export class AuthCallback extends Component {

    constructor(props) {
      super(props)

      this.state = {
         loginFinished:false,
         path:"/login"
      }
    }

    componentDidMount() {
        var queryParams = new URLSearchParams(window.location.search);
        var code = queryParams.get('code');
        var erreur = queryParams.get('error');

        
        if(code !== undefined || code !== null || code !== ''){
             axiosHttp.post(`/authCode?code=${code}`).then((res) => {
                if (res.data !== undefined && res.data.userIsAuthed !== undefined && res.data.userIsAuthed === true){
                    this.saveProfile(res.data);
                    this.setState({loginFinished:true,path:"/"});           
                }
                else{
                    this.setState({loginFinished:false,path:"/login"});
                }
            });
        }
        if(erreur !== undefined && erreur !== null && erreur !== 'null' && erreur !== ''){
            this.setState({loginFinished:true,path:"/login"});
        }
    }

    saveProfile = (data)=>{
        sessionStorage.setItem('id_token', data.id_token);
        sessionStorage.setItem('access_token', data.access_token);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        sessionStorage.setItem('displayName', data.displayName);
        sessionStorage.setItem('expires_at', JSON.stringify(data.exp));
        sessionStorage.setItem("userIsAuthorized", "true");
    }

    render() {
        const {loginFinished,path} =  this.state
        if(!loginFinished){
            return (<div>Loading ...</div>)
        }
        return (
            <Navigate to={path} replace={true} />
        )
      }
}

export default AuthCallback
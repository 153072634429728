import React, { Component } from 'react'
import { Outlet,Navigate } from 'react-router-dom'
import axiosHttp from '../utils/AxiosHttp'


export class PrivateRoute extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       authed: false
    }
  }

  componentDidMount() {
    
  }

  tokenValid = ()=>{
  
  var now = Date.now() / 1000;

  var expires_at = sessionStorage.getItem('expires_at');
  if (expires_at === undefined || expires_at === null || expires_at === "undefined" || expires_at === "null") {
    return false;
  }
  else{
    return now < JSON.parse(expires_at);
  } 
}

getRefresh = () => {
  
  var refreshToken = sessionStorage.getItem('refresh_token');

  if(refreshToken === undefined || refreshToken === null || refreshToken === ""){
    return false
  }

   axiosHttp.post(`/authRefresh?refresh_token=${refreshToken}`).then((res) => {
    if (res.data !== undefined && res.data.userIsAuthed !== undefined && res.data.userIsAuthed === true){
      sessionStorage.setItem('id_token', res.data.id_token);
      sessionStorage.setItem('access_token', res.data.access_token);
      sessionStorage.setItem('refresh_token', res.data.refresh_token);
      sessionStorage.setItem('displayName', res.data.displayName);
      sessionStorage.setItem('expires_at', JSON.stringify(res.data.exp));
      sessionStorage.setItem("userIsAuthorized", "true");
      return true;       
    }
    else{
      return false
    }}
  );

}

checkAuth = ()=>{
  var userIsAuthorized = sessionStorage.getItem("userIsAuthorized")
    
    if((userIsAuthorized !== undefined  && userIsAuthorized !== null) && (userIsAuthorized === 'true' || userIsAuthorized === true)){
        var tokenIsValid = this.tokenValid();
        return tokenIsValid ? true : this.getRefresh()
    }
    return false;

}


  render() {
    
    var authed = this.checkAuth()
    return (
      authed ? <Outlet/> : <Navigate to='/login'/>
    )
  }

}

export default PrivateRoute